
.adminSignInForm {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
    padding: 4% 3%;
    border: 1px solid #898989;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.adminSignInForm input {
    font-size: 1.1em;
    border: 1px solid #000;
    margin: 15px;
}