.passedPercentage {
    background-color: #d6ffd6;
}

.users_row > td {
    padding: 2px 6px
}

.users_row:hover {
    background-color: #f1f1f1;
}

.users_row button:not([disabled]) {
    cursor: pointer;
}