.user_table td {
    border: 1px solid #cecece
}

.user_table {
    border-collapse: collapse;
}

.user_container {
    width: 90%;
    margin: auto;
}

.formField {
    margin: 10px 0;
}

label {
    display: inline-block;
    width: 200px;
}
