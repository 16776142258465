.question-options {
    padding: 10px
}

.question-options > label {
    display: block;
    padding: 5px
}

.question-correct-answer {
    padding: 10px
}

.admin-inputs {
    min-width: 250px
}