.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.sign_out_container {
  position: sticky;
  bottom: 0;
  left: 0;
  padding: 10px;
  background-color: #FFF;
}

.sign_out_container span {
  margin: 10px;
}

.clickable {
  cursor: pointer;
}